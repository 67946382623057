import React from "react";

const  AuthContext = React.createContext({
  auth: null,
  setAuth(value) {
    this.auth = value;
  },
});

export default AuthContext;
