import React,{useState,useEffect}from "react";
import GlobalStyles from "./GlobalStyles";
import Loader from "./components/Loader/Loading";
import AuthContext from "./AuthContext";
import useLocalStorage from "./hooks/useLocalStorage";
import {TabContext} from './Context/tabContext'

const AuthApp = React.lazy(() => import("./AuthApp"));
const UnauthApp = React.lazy(() => import("./UnauthApp"));

function App() {
  const [auth, setAuth] = useLocalStorage("auth", null);
  let[state,setState] = useState({
    classMeterial:true,
    syllabus:false,
    classFolder:false,
    gradeBook:false,
    timeSheet:false
  })
	const fetchedUser = JSON.parse(localStorage.getItem("auth"));
	console.log("AccessToken",fetchedUser)
	useEffect(()=>{
		(async()=>{
			 await fetch('https://tcs-portal-backend-fastapi.herokuapp.com/user/track',{
				 method:'post',
				 headers:{
					 'AccessToken':fetchedUser?.access_token
				 }
			 })
		})()
	},[fetchedUser])
  const HandleTabChange = (condition)=>{
    switch (condition) {
			case "classMaterial":
        console.log(condition,'Condition')
				setState({
					classMeterial: true,
					syllabus: false,
					classFolder: false,
					gradeBook: false,
					timeSheet: false,
				});
				break;
			case "syllabus":
				setState({
					classMeterial: false,
					syllabus: true,
					classFolder: false,
					gradeBook: false,
					timeSheet: false,
				});
				break;
			case "classFolder":
				setState({
					classMeterial: false,
					syllabus: false,
					classFolder: true,
					gradeBook: false,
					timeSheet: false,
				});
				break;
			case "gradeBook":
				setState({
					classMeterial: false,
					syllabus: false,
					classFolder: false,
					gradeBook: true,
					timeSheet: false,
				});
				break;
			case "timeSheet":
				setState({
					classMeterial: false,
					syllabus: false,
					classFolder: false,
					gradeBook: false,
					timeSheet: true,
				});
				break;
			default:
				break;
    }
  }
  return (
    <div className="App">
      <GlobalStyles />
      <AuthContext.Provider value={{ auth: auth, setAuth: setAuth }}>
      <TabContext.Provider value={{state,setState:HandleTabChange}}>
        <React.Suspense fallback={<Loader />}>
          {auth ? <AuthApp /> : <UnauthApp />}  
        </React.Suspense>
        </TabContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
